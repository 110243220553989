import React from "react";
// Customizable Area Start
import {
  Text,
  Image,
  TouchableOpacity,
  View,
  ScrollView,
  SafeAreaView,
  StyleSheet,
  Dimensions
  // Customizable Area Start
  // Customizable Area End
} from "react-native";
import { leftArrow, cross } from "./assets";
import WeatherDetailsController, {
  Props,
  configJSON
} from "./WeatherDetailsController";
import Scale from "../../../components/src/Scale";
// Customizable Area End
export default class WeatherDetails extends WeatherDetailsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  weatherDataRenderer(startDate: string) {
    return this.state.weatherData?.map((data, index: number) => {
      return (
        <View key={index + data.id} style={{ marginLeft: this.marginHandler(index), paddingHorizontal: Scale(5), paddingVertical: Scale(15) }}>
          <View style={styles.weatherImageContainer}>
            <Image
              resizeMode="contain"
              source={data.image}
              style={styles.weatherImage}
            />
            <Text style={styles.tempText}>{data.temp + "\u00B0" + "C"}</Text>
          </View>
          <View style={styles.dateContainerWithBackGround}>
            <Text style={styles.dateText}>{startDate}</Text>
          </View>
        </View>
      )
    })
  }
  // Customizable Area End

  render() {
    let startDate = this.props.navigation.getParam("startDate");
    let location = this.props.navigation.getParam("location");
    return (
      // Customizable Area Start
      <SafeAreaView style={styles.container}>
        <View style={{ flex: 0.01 }} />
        <View
          style={styles.subContainer}
        >
          <TouchableOpacity
            testID="goBackBtn"
            onPress={() =>
              this.goBackHandler()
            }
          >
            <Image
              source={leftArrow}
              style={styles.goBackImage}
              resizeMode="contain"
            />
          </TouchableOpacity>
          <Text
            style={styles.headerText}
          >
            {configJSON.weatherData}
          </Text>
        </View>
        <View style={styles.weatherContainer}>
          <View style={styles.locationContainer}>
            <Text style={styles.suggestionText}>{location}</Text>
            <TouchableOpacity
              testID="toPreviousPageBtn"
              onPress={() => {
                this.goBackHandler()
              }}
            >
              <Image
                source={cross}
                style={[styles.goBackImage]}
                resizeMode="contain"
              />
            </TouchableOpacity>
          </View>
          <View style={styles.subHeading}>
            <Text style={styles.subHeadingText}>Weather Conditions</Text>
          </View>
          <View style={styles.weatherDataContainer}>
            <ScrollView
              horizontal
              contentContainerStyle={{ flexWrap: 'wrap' }}
            >
              <>
                {
                  this.weatherDataRenderer(startDate)
                }
              </>
            </ScrollView>
          </View>
        </View >
      </SafeAreaView >
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  container: {
      flex: 1,
      backgroundColor: "white",
  },
  subContainer: {
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      marginLeft: Scale(10)
  },
  subView: {
      flex: 0.94,
      padding: Scale(10),
      justifyContent: "space-between"
  },
  dateContainer: {
      flexDirection: "row",
      alignItems: "center",
      marginTop: Scale(30),
      backgroundColor: "#E5E5E5",
      width: Dimensions.get("screen").width - Scale(20),
      height: Scale(50),
      borderRadius: Scale(15),
      paddingHorizontal: Scale(10)
  },
  imageContainer: {
      flex: 1,
      alignItems: "flex-end",
      justifyContent: "center"
  },
  goBackImage: {
      width: Scale(20),
      height: Scale(20),
      tintColor: "#000000"
  },
  headerText: {
      flex: 1,
      textAlign: "center",
      fontSize: Scale(20),
      fontFamily: "PlayfairDisplay-Bold",
      letterSpacing: Scale(0.25),
  },

  explanationText: {
      fontSize: Scale(14),
      fontFamily: "Nunito-SemiBold",
      letterSpacing: Scale(0.15)
  },
  btnText: {
      fontSize: Scale(13),
      fontFamily: "Nunito-Bold",
      fontWeight: "700",
      textTransform: "uppercase",
      letterSpacing: Scale(0.15)
  },
  acceptBtn: {
      backgroundColor: "#CDB8D7",
      width: Dimensions.get("screen").width - Scale(20),
      height: Scale(50),
      justifyContent: "center",
      alignItems: "center",
      borderRadius: Scale(15),
      marginBottom: Scale(10)
  },
  applyBtn: {
      backgroundColor: "#CDB8D7",
      justifyContent: "center",
      alignItems: "center",
      marginBottom: Scale(10),
      marginTop: Scale(15),
      width: Dimensions.get("screen").width - Scale(120),
      height: Scale(40),
      borderRadius: Scale(20),
  },
  card: {
      flex: 0.47,
      backgroundColor: "white",
      zIndex: 5,
      borderRadius: Scale(15),
      padding: Scale(25),
  },
  elevation: {
      shadowOffset: { width: 0, height: 4 },
      shadowOpacity: 1,
      shadowRadius: 3,
      shadowColor: "gray",
      elevation: 4
  },
  textStyle1: {
      marginTop: Scale(10),
      fontSize: Scale(18),
      fontFamily: "PlayfairDisplay-SemiBold",
      letterSpacing: Scale(0.2)
  },
  textStyle2: {
      marginTop: Scale(15),
      fontSize: Scale(14),
      fontFamily: "PlayfairDisplay-Regular",
      letterSpacing: Scale(0.15)
  },
  suggestionText: {
      fontSize: Scale(16),
      fontFamily: "Nunito-SemiBold",
      letterSpacing: Scale(0.15),
      fontWeight: "600"
  },
  locationContainer: {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      marginTop: Scale(30),
      backgroundColor: "#E5E5E5",
      width: Dimensions.get("screen").width - Scale(20),
      height: Scale(50),
      borderRadius: Scale(15),
      padding: Scale(15)
  },
  weatherDataContainer: {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      marginTop: Scale(10),
      backgroundColor: "#E5E5E5",
      width: Dimensions.get("screen").width - Scale(20),
      borderRadius: Scale(15)
  },
  weatherImageContainer: {
      flexDirection: "row",
      alignItems: "center"
  },
  weatherImage: {
      height: Scale(50),
      width: Scale(80)
  },
  dateContainerWithBackGround: {
      marginTop: Scale(8),
      backgroundColor: "#CDB8D7",
      borderRadius: Scale(5),
      padding: Scale(5)
  },
  subHeading: {
      marginTop: Scale(30),
      padding: Scale(5)
  },
  subHeadingText: {
      fontFamily: "Nunito-Bold",
      fontSize: Scale(18),
      letterSpacing: Scale(0.15)
  },
  dateText: {
      textAlign: "center",
      fontSize: Scale(15),
      fontFamily: "Nunito-SemiBold",
  },
  weatherContainer: {
      flex: 0.94,
      padding: Scale(10)
  },
  tempText: {
      fontSize: Scale(14),
      fontFamily: "Nunito-SemiBold",
  },
  locationModal: {
      backgroundColor: "#E5E5E5",
      marginTop: Scale(5),
      borderRadius: Scale(10)
  },
  locationSubContainer: {
      borderBottomColor: "#D2D3D5",
      paddingVertical: Scale(12)
  }
});
// Customizable Area End