import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { useRunEngine } from "../../utilities/src/hooks/useRunEngine";
import { useBlockHelpers } from "../../utilities/src/hooks/useBlockHelpers";
import AllEventsView from "./AllEventsView";

// Customizable Area Start
import React, { useEffect, useRef, useState } from "react";
import { IEventKind, IEventList, IEvent } from "./types";
// Customizable Area End

export const configJSON = require("./config");

export interface ViewProps {
  // Customizable Area Start
  testID: string;
  events: IEvent[] | null;
  isLoading: boolean;
  createEventButtonProps: {
    onPress: () => void;
    onClick: () => void;
  };
  eventCardProps: {
    onPress: (eventId: number) => void;
    onClick: (eventId: number) => void;
  };
  // Customizable Area End
}

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

const subscribedMessages = [
  // Customizable Area Start
  MessageEnum.RestAPIResponceMessage,
  MessageEnum.SessionResponseMessage,
  // Customizable Area End
];

const AllEvents: React.FC<Props> = ({ navigation, id }) => {
  // Customizable Area Start
  const getEventsCallId = useRef("");
  // Customizable Area End

  // Customizable Area Start
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [events, setEvents] = useState<IEventList | null>(null);
  const [token, setToken] = useState<string>("");

  const tokenRef = useRef<string>("");
  tokenRef.current = token;
  // Customizable Area End

  // Customizable Area Start
  const {
    sendBlockMessage,
    sendNetworkRequest,
    setReceiveCallback,
    subscribe,
    unsubscribeFromMessage,
  } = useRunEngine();

  const { extractNetworkResponse } = useBlockHelpers();

  const getToken = () => {
    const message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    sendBlockMessage(message);
  };

  const getEvents = (tokenArg?: string) => {
    setIsLoading(true);
    const headers = {
      "Content-Type": configJSON.apiContentType,
      token: tokenArg || tokenRef.current,
    };

    sendNetworkRequest(
      getEventsCallId,
      configJSON.getEventsMethod,
      configJSON.eventsEndpoint,
      headers
    );
  };
  // Customizable Area End

  const receive = (from: string, message: Message) => {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let resToken = message.getData(getName(MessageEnum.SessionResponseToken));
      setToken(resToken);
      if (resToken) {
        getEvents(resToken);
      }
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const { apiRequestCallId, responseJson } =
        extractNetworkResponse(message);
      if (responseJson.data) {
        if (apiRequestCallId === getEventsCallId.current) {
          setEvents(responseJson.data);
          setIsLoading(false);
          getEventsCallId.current = "";
        }
      } else if (responseJson.errors) {
        setIsLoading(false);
      }
    }
    // Customizable Area End
  };

  // Customizable Area Start
  useEffect(() => {
    setReceiveCallback(receive);

    subscribedMessages.forEach((message) => subscribe(message));

    if (!token) {
      getToken();
    }

    return () => {
      subscribedMessages.forEach((message) => unsubscribeFromMessage(message));
    };
  }, [token]);

  const flattenEvents = (eventsObject: IEventList | null) => {
    if (!eventsObject) {
      return [];
    }
    const allEvents: IEvent[] = [];
    const keys = Object.keys(eventsObject);
    keys.forEach((key) => {
      const eventKind: IEventKind = key as IEventKind;
      const currentEvents = eventsObject[eventKind];
      currentEvents?.forEach((event: IEvent) => {
        event.event_type = eventKind;
        allEvents.push(event);
      });
    });
    return allEvents;
  };

  const navigateToEventDetail = (eventId: number) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "EventDetail"
    );

    message.addData(getName(MessageEnum.NavigationPropsMessage), {
      navigation,
      id,
    });

    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {
      eventId,
    });
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

    sendBlockMessage(message);
  };

  const handleCreateEvent = () => {
    navigation.navigate("CreateEvent");
  };

  const handleEventCardPress = (eventId: number) => {
    navigateToEventDetail(eventId);
  };

  const createEventButtonProps = {
    onPress: handleCreateEvent,
    onClick: handleCreateEvent,
  };

  const eventCardProps = {
    onPress: handleEventCardPress,
    onClick: handleEventCardPress,
  };

  const viewProps: ViewProps = {
    testID: "AllEventsView",
    events: flattenEvents(events),
    isLoading,
    createEventButtonProps,
    eventCardProps,
  };
  // Customizable Area End
  return <AllEventsView {...viewProps} />;
};

export default AllEvents;
