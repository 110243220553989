Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "AdminConsole3";
exports.labelBodyText = "AdminConsole3 Body";
exports.heading = "Date";
exports.locationHeading = "Heading";
exports.apply = "Apply";
exports.eventLocation = "Event Location";
exports.startDate = "Start Date";
exports.endDate = "End Date";
exports.btnText = "Next Step";
exports.helpText1 = "Before we can help you with the weather data.";
exports.helpText2 = "we need you to help us with some information";
exports.weatherData = "Weather Data";

exports.btnExampleTitle = "CLICK ME";
// Customizable Area End
