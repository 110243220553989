import React from "react";

const Context = React.createContext<{
  initLanguage: Function;
  setLanguageToAsyncStorage: Function
}>({
  initLanguage: () => {},
  setLanguageToAsyncStorage: () => {}
});

export default Context;