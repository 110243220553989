import i18n from "i18next";
import { initReactI18next } from "react-i18next";
const enus = require("../translationFiles/enus.json");
import { getStorageData } from "../../../../../framework/src/Utilities";

export const langaugeFunction = async () => {
  let languageData: string | null = "";
  let languename = "en";
  let parseData: typeof enus = enus;

  languageData = await getStorageData("langDataController")
  languename = (await getStorageData("languename")) || "en";

  if(languageData){
    try{
      parseData = await JSON.parse(languageData);
    }catch (error){
      console.error(error);
    }
  }

  const resources = {
    enus: {
      translation: enus,
    },
    [languename]: {
      translation: parseData,
    },
  };
  try {
    await i18n.use(initReactI18next).init({
      lng: languename,
      fallbackLng: "enus",
      compatibilityJSON: "v2",
      resources,
      interpolation: {
        escapeValue: false,
      },
      react: {
        useSuspense: true,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

// export default i18n;
