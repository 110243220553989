import * as React from "react";
// Customizable Area Start
import {
  Container,
  Box,
  Button,
  Typography,
  Card,
  CardContent,
} from "@mui/material";
import {
  birthday,
  school_drop,
  school_pick,
  getTogether,
  meeting,
  outForCoffee,
  gym,
  flight,
  vet,
  rent,
  fees,
  activity,
  custom,
  doctor,
} from "./assets";
import moment from "moment";
// Customizable Area End

import { configJSON, ViewProps } from "./AllEvents";

// Customizable Area Start
interface IconOptions {
  [key: string]: any;
}

const optionsIconMap: IconOptions = {
  Birthday: birthday,
  "School Drop Off": school_drop,
  "School Pick Up": school_pick,
  "Get Together": getTogether,
  Meeting: meeting,
  "Out for Coffee": outForCoffee,
  Gym: gym,
  Flight: flight,
  Vet: vet,
  Rent: rent,
  Fees: fees,
  Activity: activity,
  Custom: custom,
  Doctor: doctor,
};
// Customizable Area End

const AllEventsView: React.FC<ViewProps> = ({
  // Customizable Area Start
  testID,
  events,
  isLoading,
  createEventButtonProps,
  eventCardProps,
  // Customizable Area End
}) => {
  // Customizable Area Start
  if (isLoading) {
    return (
      <div>
        <h2>{configJSON.loading}</h2>
      </div>
    );
  }

  if (!events?.length) {
    return (
      <div>
        <h2>{configJSON.noData}</h2>
      </div>
    );
  }

  return (
    <Container style={webStyle.container} data-testid={testID} maxWidth={"sm"}>
      <Button variant="contained" onClick={createEventButtonProps.onClick}>
        {configJSON.createEvent}
      </Button>
      <Box sx={webStyle.mainWrapper}>
        {events.map((event) => {
          const date = moment(event.date).format("DD MMM YYYY");

          return (
            <Card
              key={event.id}
              style={webStyle.cardContainer}
              onClick={() => eventCardProps.onClick(event.id)}
              data-testid={"EventCard"}
            >
              <CardContent style={webStyle.cardContent}>
                <Box sx={webStyle.cardLeft}>
                  <Box style={webStyle.eventKindContainer}>
                    <img
                      style={webStyle.image}
                      src={optionsIconMap[event.event_type]}
                    />
                    <Typography>{event.event_type}</Typography>
                  </Box>
                  <Typography variant="h5">{event.title}</Typography>
                  <Typography>{event.notes}</Typography>
                </Box>
                <Box>
                  <Typography>{date}</Typography>
                </Box>
              </CardContent>
            </Card>
          );
        })}
      </Box>
    </Container>
  );
  // Customizable Area End
};

const webStyle = {
  // Customizable Area Start
  container: {
    display: "flex",
    flexDirection: "column" as const,
    gap: "16px",
    marginTop: "16px",
  },
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    paddingBottom: "32px",
    background: "#fff",
  },
  link: {
    textDecoration: "none",
    marginBottom: "16px",
  },
  cardContainer: {
    textDecoration: "none",
    marginBottom: "16px",
    cursor: "pointer",
  },
  cardContent: {
    display: "flex",
    justifyContent: "space-between",
  },
  eventKindContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: "8px",
    border: "1px solid #6200EE",
    borderRadius: "4px",
    padding: "4px",
    gap: "8px",
  },
  image: {
    width: "15px",
    height: "15px",
  },
  cardLeft: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  // Customizable Area End
};

export default AllEventsView;
