import React from "react";
// Customizable Area Start
import {
  Text,
  Image,
  TouchableOpacity,
  View,
  TextInput,
  ScrollView,
  SafeAreaView,
  Dimensions,
  StyleSheet
  // Customizable Area Start
  // Customizable Area End
} from "react-native";
import { leftArrow } from "./assets";
import LocationFinderController, {
  Props,
  configJSON
} from "./LocationFinderController";
import Scale from "../../../components/src/Scale";
// Customizable Area End
export default class LocationFinder extends LocationFinderController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  locationModal() {
    return this.state.location.length > 0 && this.state.isSuggestionModalOpen ?
      <View style={styles.locationModal}>
        <ScrollView style={{ padding: Scale(15) }}>
          {this.state.suggestedLocationData?.map((data, index: number) => {
            return (
              <TouchableOpacity
                testID={`optionBtn${index}`}
                key={index + data?.id}
                style={[styles.locationSubContainer, { borderBottomWidth: this.borderHandler(index) }]}
                onPress={() => {
                  this.suggestionHandler(data)
                }}
              >
                <Text style={styles.suggestionText}>{data.name}</Text>
              </TouchableOpacity>
            )
          })}
        </ScrollView>
      </View>
      :
      <View />
  }
  // Customizable Area End

  render() {

    const { location } = this.state
    return (
      // Customizable Area Start
      <SafeAreaView style={styles.container}>
        <View style={{ flex: 0.01 }} />
        <View
          style={styles.subContainer}
        >
          <TouchableOpacity
            testID="goBackBtn"
            onPress={() => this.goBack()}
          >
            <Image
              source={leftArrow}
              style={styles.goBackImage}
              resizeMode="contain"
            />
          </TouchableOpacity>
          <Text
            style={styles.headerText}
          >
            {configJSON.eventLocation}
          </Text>
        </View>
        <View style={styles.subView}>
          <View>
            <Text style={styles.textStyle2}>{configJSON.helpText1}</Text>
            <Text style={styles.textStyle1}>{configJSON.helpText2} </Text>
            <View style={[styles.dateContainer, { justifyContent: "space-between" }]}>
              <TextInput
                testID="locationText"
                value={location}
                placeholder={configJSON.eventLocation}
                onFocus={() => {
                  this.focusHandler()
                }}
                style={[styles.suggestionText, { width: Dimensions.get("screen").width - Scale(90), marginRight: Scale(10) }]}
                onChangeText={(text) => {
                  this.locationTextHandler(text)
                }}
              />
              <TouchableOpacity
                style={{ marginRight: Scale(10) }}
                testID="clearLocationBtn"
                onPress={() => this.clearLocationData()}
              >
                <Image
                  source={this.imageHandler()}
                  style={[styles.goBackImage]}
                  resizeMode="contain"
                />
              </TouchableOpacity>
            </View>
            {
              this.locationModal()
            }
          </View>
          <TouchableOpacity
            testID="navigateToNextPageBtn"
            style={[styles.acceptBtn, { opacity: this.opacityHandler() }]}
            disabled={this.btnDisableHandler()}
            onPress={() => {
              this.navigationHandler()
            }}>
            <Text style={styles.btnText}>{configJSON.btnText}</Text>
          </TouchableOpacity>
        </View>
      </SafeAreaView >
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  container: {
      flex: 1,
      backgroundColor: "white",
  },
  subContainer: {
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      marginLeft: Scale(10)
  },
  subView: {
      flex: 0.94,
      padding: Scale(10),
      justifyContent: "space-between"
  },
  dateContainer: {
      flexDirection: "row",
      alignItems: "center",
      marginTop: Scale(30),
      backgroundColor: "#E5E5E5",
      width: Dimensions.get("screen").width - Scale(20),
      height: Scale(50),
      borderRadius: Scale(15),
      paddingHorizontal: Scale(10)
  },
  imageContainer: {
      flex: 1,
      alignItems: "flex-end",
      justifyContent: "center"
  },
  goBackImage: {
      width: Scale(20),
      height: Scale(20),
      tintColor: "#000000"
  },
  headerText: {
      flex: 1,
      textAlign: "center",
      fontSize: Scale(20),
      fontFamily: "PlayfairDisplay-Bold",
      letterSpacing: Scale(0.25),
  },

  explanationText: {
      fontSize: Scale(14),
      fontFamily: "Nunito-SemiBold",
      letterSpacing: Scale(0.15)
  },
  btnText: {
      fontSize: Scale(13),
      fontFamily: "Nunito-Bold",
      fontWeight: "700",
      textTransform: "uppercase",
      letterSpacing: Scale(0.15)
  },
  acceptBtn: {
      backgroundColor: "#CDB8D7",
      width: Dimensions.get("screen").width - Scale(20),
      height: Scale(50),
      justifyContent: "center",
      alignItems: "center",
      borderRadius: Scale(15),
      marginBottom: Scale(10)
  },
  applyBtn: {
      backgroundColor: "#CDB8D7",
      justifyContent: "center",
      alignItems: "center",
      marginBottom: Scale(10),
      marginTop: Scale(15),
      width: Dimensions.get("screen").width - Scale(120),
      height: Scale(40),
      borderRadius: Scale(20),
  },
  card: {
      flex: 0.47,
      backgroundColor: "white",
      zIndex: 5,
      borderRadius: Scale(15),
      padding: Scale(25),
  },
  elevation: {
      shadowOffset: { width: 0, height: 4 },
      shadowOpacity: 1,
      shadowRadius: 3,
      shadowColor: "gray",
      elevation: 4
  },
  textStyle1: {
      marginTop: Scale(10),
      fontSize: Scale(18),
      fontFamily: "PlayfairDisplay-SemiBold",
      letterSpacing: Scale(0.2)
  },
  textStyle2: {
      marginTop: Scale(15),
      fontSize: Scale(14),
      fontFamily: "PlayfairDisplay-Regular",
      letterSpacing: Scale(0.15)
  },
  suggestionText: {
      fontSize: Scale(16),
      fontFamily: "Nunito-SemiBold",
      letterSpacing: Scale(0.15),
      fontWeight: "600"
  },
  locationContainer: {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      marginTop: Scale(30),
      backgroundColor: "#E5E5E5",
      width: Dimensions.get("screen").width - Scale(20),
      height: Scale(50),
      borderRadius: Scale(15),
      padding: Scale(15)
  },
  weatherDataContainer: {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      marginTop: Scale(10),
      backgroundColor: "#E5E5E5",
      width: Dimensions.get("screen").width - Scale(20),
      borderRadius: Scale(15)
  },
  weatherImageContainer: {
      flexDirection: "row",
      alignItems: "center"
  },
  weatherImage: {
      height: Scale(50),
      width: Scale(80)
  },
  dateContainerWithBackGround: {
      marginTop: Scale(8),
      backgroundColor: "#CDB8D7",
      borderRadius: Scale(5),
      padding: Scale(5)
  },
  subHeading: {
      marginTop: Scale(30),
      padding: Scale(5)
  },
  subHeadingText: {
      fontFamily: "Nunito-Bold",
      fontSize: Scale(18),
      letterSpacing: Scale(0.15)
  },
  dateText: {
      textAlign: "center",
      fontSize: Scale(15),
      fontFamily: "Nunito-SemiBold",
  },
  weatherContainer: {
      flex: 0.94,
      padding: Scale(10)
  },
  tempText: {
      fontSize: Scale(14),
      fontFamily: "Nunito-SemiBold",
  },
  locationModal: {
      backgroundColor: "#E5E5E5",
      marginTop: Scale(5),
      borderRadius: Scale(10)
  },
  locationSubContainer: {
      borderBottomColor: "#D2D3D5",
      paddingVertical: Scale(12)
  }
});
// Customizable Area End