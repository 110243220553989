import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Button,
  InputLabel,
  List,
  ListItem,
  Divider,
  Grid,
  Tabs,
  Tab,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  MenuItem,
  ListItemIcon,
  Checkbox,
  FormControl,
  ListSubheader,
  IconButton,
  Modal,
  TextField,
  Typography,
  Select,
} from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";
import {
  createTheme,
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from "@mui/material/styles";
import ListItemText from "@mui/material/ListItemText";
import Pagination from "@mui/material/Pagination";
import Slider from "@mui/material/Slider";
import StarIcon from "@mui/icons-material/Star";
import FilterAltIcon from "@mui/icons-material/FilterListSharp";
import CircularProgress from "@mui/material/CircularProgress";

import { remove, addNew, removeItem } from "./assets";

const theme = createTheme({
  palette: {
    primary: {
      main: "#11379a",
      contrastText: "#ffff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import GamificationController, {
  Props,
  configJSON,
  LeaderBoardItem,
} from "./GamificationController";

// declare module "@mui/styles/defaultTheme" {
//   // eslint-disable-next-line @typescript-eslint/no-empty-interface
//   interface DefaultTheme extends Theme {}
// }

export default class Gamification extends GamificationController {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // render start Icon
  renderStar = (rank: number) => {
    const startIcon = [];
    for (let indx = 0; indx < rank; indx++) {
      startIcon.push(
        <StarIcon key={`startIcon-${indx}`} style={webStyle.starContainer} />
      );
    }
    return startIcon;
  };

  renderList = () => {
    return (
      <Box sx={webStyle.listConatiner}>
        <List style={webStyle.w100}>
          <Box style={webStyle.header}>
            <Box sx={webStyle.selectCourseContainer}>
              <FormControl fullWidth>
                <Select
                  id="select-course"
                  value={this.state.selectedCourseForLeaderBoard}
                  label={configJSON.selectCourse}
                  onChange={(event) =>
                    this.handleSelectedCourseChange(
                      event.target.value as number
                    )
                  }
                >
                  {this.state.courses.map((course) => (
                    <MenuItem value={course.attributes.id}>
                      {course.attributes.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box style={webStyle.tabScoreheader}>
              {`${configJSON.labelTopScoreText}:`}
              {this.state.userList[0] ? this.state.userList[0].top_score : 0}
            </Box>
            <Grid
              item
              xs={1}
              onClick={() => this.setFilter(true)}
              style={webStyle.filterContain}
              data-test-id="filter"
            >
              <FilterAltIcon />
            </Grid>
          </Box>
          {this.getListPerPage().length > 0 ? (
            this.getListPerPage().map(
              (item: LeaderBoardItem, index: number) => {
                return (
                  <ListItem
                    key={`user-${index}`}
                    style={webStyle.ListContainer}
                  >
                    <ListItemText
                      id={`list-user-${index}`}
                      primary={
                        <Grid container alignItems="center">
                          <Grid item xs={12} md={5}>
                            {item.performance_tracker_course_id && (
                              <Box>
                                {`${configJSON.labelCourseText} : `}
                                {item.course_name}
                              </Box>
                            )}
                          </Grid>
                          <Grid item xs={12} md={5}>
                            {item.account_id && (
                              <Box>
                                {`${configJSON.labelUserText} : `}
                                {item.account_id}
                              </Box>
                            )}
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={2}
                            style={webStyle.scoreConatiner}
                          >
                            <Box>
                              {`${configJSON.labelScoreText} : `}
                              {item.total || 0}
                            </Box>
                          </Grid>
                        </Grid>
                      }
                      secondary={
                        <Grid container alignItems="center">
                          <Grid item xs={1} component="span">
                            {`${configJSON.labelRankText}:`}
                          </Grid>
                          <Grid item xs={11}>
                            {this.renderStar(Number(item.ranking))}
                          </Grid>
                        </Grid>
                      }
                    />
                  </ListItem>
                );
              }
            )
          ) : (
            <Box style={webStyle.notFound}>{configJSON.labelNoRecordText}</Box>
          )}
        </List>
        <Divider />
        <Box component="span">
          <Pagination
            count={this.getPageCount()}
            page={this.state.page}
            defaultPage={1}
            color="primary"
            size="large"
            showFirstButton
            showLastButton
            style={webStyle.paginator}
            data-test-id="pagination"
            onChange={(e, value) => this.setPagination(value)}
          />
        </Box>
      </Box>
    );
  };

  renderTabContainer = () => {
    return (
      <Grid container alignItems="center">
        <Grid item xs={11}>
          <Tabs
            value={this.state.tabIndex}
            onChange={(event, value: number) => this.tabChange(value)}
            aria-label="gamification-tab"
            style={webStyle.tabCenter}
            data-test-id="tabs"
          >
            <Tab label={configJSON.labelRankingText} />
            <Tab label={configJSON.labelLeaderboardText} />
            {this.state.userRole === "admin" && (
              <Tab label={configJSON.ranges} />
            )}
            {this.state.userRole === "admin" && (
              <Tab label={configJSON.mappings} />
            )}
          </Tabs>
        </Grid>
      </Grid>
    );
  };

  renderDialog = () => {
    return (
      <Dialog
        open={this.state.filterFlag}
        onClose={() => this.setFilter(false)}
        data-test-id="filter-dialog"
      >
        <DialogTitle>{configJSON.labelFilterText}</DialogTitle>
        <DialogContent style={webStyle.diaogContainer}>
          <Grid container alignItems="center">
            <Grid item xs={6}>
              <Box style={webStyle.dialogScore}>
                {configJSON.labelRankingText}
                <Slider
                  defaultValue={[
                    this.state.selectedRank.min,
                    this.state.selectedRank.max,
                  ]}
                  valueLabelDisplay="auto"
                  data-test-id="slider-rank"
                  onChange={(
                    event: Event,
                    value: number | number[],
                    activeThumb: number
                  ) => {
                    this.populateRankData(
                      Array.isArray(value) ? value[0] : value,
                      Array.isArray(value) ? value[1] : value
                    );
                  }}
                  min={1}
                  max={5}
                />
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box style={webStyle.dialogScore}>
                {configJSON.labelScoreText}
                <Slider
                  getAriaLabel={() => configJSON.labelScoreText}
                  defaultValue={[
                    this.state.selectedScore.min,
                    this.state.selectedScore.max,
                  ]}
                  valueLabelDisplay="auto"
                  data-test-id="slider-score"
                  min={1}
                  max={Number(
                    (this.state.originalList?.[0] &&
                      this.state.originalList?.[0]?.["top_score"]) ||
                      100
                  )}
                  onChange={(
                    event: Event,
                    value: number | number[],
                    activeThumb: number
                  ) => {
                    this.populateScoreData(
                      Array.isArray(value) ? value[0] : value,
                      Array.isArray(value) ? value[1] : value
                    );
                  }}
                />
              </Box>
            </Grid>
            {this.state.courseIds?.length > 0 && (
              <Grid item xs={6}>
                <Box style={webStyle.dialogCheck}>
                  <FormControl fullWidth>
                    <InputLabel>{configJSON.labelCourseText}</InputLabel>
                    <Select
                      labelId="select-course"
                      data-test-id="select-course"
                      label={configJSON.labelCourseText}
                      multiline
                      onChange={(event: SelectChangeEvent) => {
                        const {
                          target: { value },
                        } = event;
                        this.populateCoursesData(
                          typeof value === "string" ? value.split(",") : value
                        );
                      }}
                      multiple
                      value={this.state.selectedCourse.join(", ")}
                      renderValue={(selected) => {
                        if (selected) {
                          return this.getNameByID(
                            this.state.courseIds,
                            selected?.split(", ")
                          )
                        }
                      }}
                    >
                      {this.state.courseIds?.map(
                        (
                          option: {
                            id?: string;
                            name?: string;
                          },
                          index: number
                        ) => (
                          <MenuItem key={`course-${index}`} value={option.id}>
                            <ListItemIcon>
                              <Checkbox
                                checked={
                                  this.state.selectedCourse.indexOf(
                                    option.id as string | number
                                  ) > -1
                                }
                              />
                            </ListItemIcon>
                            <ListItemText primary={option.name} />
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
            )}
            {this.state.userIds?.length > 0 && (
              <Grid item xs={6}>
                <Box style={webStyle.dialogCheck}>
                  <FormControl fullWidth>
                    <InputLabel id="select-user">
                      {configJSON.labelUserText}
                    </InputLabel>
                    <Select
                      labelId="select-user"
                      data-test-id="select-user"
                      label={configJSON.labelUserText}
                      onChange={(event: SelectChangeEvent) => {
                        const {
                          target: { value },
                        } = event;
                        this.populateUsersData(
                          typeof value === "string" ? value.split(",") : value
                        );
                      }}
                      multiple
                      value={this.state.selectedUser.join(", ")}
                      renderValue={(selected: string) => {
                        console.log("selected", selected)
                        if (selected) {
                          return this.getNameByID(
                            this.state.userIds,
                            selected?.split(", ")
                          )
                        }
                      }
     
                      }
                    >
                      {this.state.userIds?.map(
                        (
                          option: {
                            id?: string | number;
                            name?: string;
                          },
                          index: number
                        ) => (
                          <MenuItem key={`user-${index}`} value={option.id}>
                            <ListItemIcon>
                              <Checkbox
                                checked={
                                  this.state.selectedUser.indexOf(
                                    option.id as string | number
                                  ) > -1
                                }
                              />
                            </ListItemIcon>
                            <ListItemText primary={option.name} />
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            data-test-id="reset-button"
            onClick={() => this.resetHandle()}
          >
            {configJSON.btnReset}
          </Button>
          <Button
            data-test-id="cancel-button"
            onClick={() => this.setFilter(false)}
          >
            {configJSON.btnCancel}
          </Button>
          <Button
            data-test-id="apply-button"
            onClick={() => this.handleFilter()}
          >
            {configJSON.btnApply}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  renderRanges = () => {
    return (
      <Box>
        <Box sx={webStyle.rangeButtonContainer}>
          <Button
            style={webStyle.rangeButton}
            variant="contained"
            onClick={this.handleCreateRangeModalOpen}
            data-test-id="createRangeBtn"
          >
            {configJSON.createRange}
          </Button>
        </Box>

        <List
          style={webStyle.rangeList}
          component="nav"
          aria-labelledby="nested-list-subheader"
          subheader={
            <ListSubheader component="div" id="nested-list-subheader">
              {configJSON.ranges}
            </ListSubheader>
          }
        >
          {this.state.ranges.length > 0 &&
            this.state.ranges.map((range, index) => (
              <ListItem key={`range-${index}`}>
                <Grid item xs={10}>
                  <ListItemText
                    primary={<Typography>{range.attributes.id}</Typography>}
                    secondary={Object.keys(range.attributes.range_data).map(
                      (key) => (
                        <Typography>
                          {key + " : " + range.attributes.range_data[key]}
                        </Typography>
                      )
                    )}
                  />
                </Grid>
                <Grid item xs={2}>
                  <IconButton
                    data-test-id={"removeRangeBtn" + index}
                    onClick={() => this.handleDeleteRange(range.id)}
                    size="large"
                  >
                    <img src={remove} />
                  </IconButton>
                </Grid>
              </ListItem>
            ))}
        </List>
      </Box>
    );
  };

  renderCreateRangeModal = () => {
    return (
      <Modal
        open={this.state.createRangeModalOpen}
        onClose={this.handleCreateRangeModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={webStyle.createRangeForm}>
          {this.state.rangeTextFields.map((item, index) => (
            <Box key={item.id} sx={webStyle.rangeTextFieldContainer}>
              <TextField
                id="standard-helperText"
                label={configJSON.range}
                variant="standard"
                defaultValue={item.rangeField}
                onChange={(event) =>
                  this.handleChangeRangeField(event.target.value, item.id)
                }
                style={webStyle.rangeTextField}
                data-test-id={"rangeTextField" + index}
              />
              <TextField
                id="standard-helperText"
                label={configJSON.value}
                variant="standard"
                defaultValue={item.starField}
                onChange={(event) =>
                  this.handleChangeValueField(event.target.value, item.id)
                }
                style={webStyle.rangeTextField}
                data-test-id={"valueTextField" + index}
              />
              <IconButton
                onClick={() => this.removeTextField(item.id)}
                size="large"
              >
                <img src={removeItem} />
              </IconButton>
            </Box>
          ))}
          <IconButton onClick={this.addRangeTextField} size="large">
            <img src={addNew} />
          </IconButton>

          <Box sx={webStyle.createRangeButtonContainer}>
            <Button
              style={webStyle.rangeButton}
              variant="contained"
              onClick={this.handleCreateRange}
            >
              {configJSON.createRange}
            </Button>
            <Button
              style={webStyle.rangeButton}
              variant="contained"
              onClick={this.handleCreateRangeModalClose}
            >
              {configJSON.btnCancel}
            </Button>
          </Box>
        </Box>
      </Modal>
    );
  };

  renderMappings = () => {
    return (
      <Box>
        <Box sx={webStyle.mappingButtonContainer}>
          <Button
            style={webStyle.rangeButton}
            variant="contained"
            onClick={() => this.handleCreateMappingModalOpen(false)}
            data-test-id="createMappingBtn"
          >
            {configJSON.createMapping}
          </Button>
        </Box>
      </Box>
    );
  };

  renderCreateUpdateMappingsModal = () => {
    return (
      <Modal
        open={this.state.createMappingModalOpen}
        onClose={this.handleCreateMappingModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={webStyle.createRangeForm}>
          <TextField
            id="standard-helperText"
            label={configJSON.courseId}
            variant="standard"
            type="number"
            value={
              this.state.createMappingCourseId
                ? this.state.createMappingCourseId
                : ""
            }
            onChange={(event) =>
              this.handleCourseIdTextChange(Number(event.target.value))
            }
            style={webStyle.rangeTextField}
            data-test-id="createMappingCourseIdTextField"
          />
          <TextField
            id="standard-helperText"
            label={configJSON.rangeId}
            variant="standard"
            type="number"
            value={
              this.state.createMappingRangeId
                ? this.state.createMappingRangeId
                : ""
            }
            onChange={(event) =>
              this.handleRangeIdTextChange(Number(event.target.value))
            }
            style={webStyle.rangeTextField}
            data-test-id="createMappingRangeIdTextField"
          />

          <Typography style={webStyle.errorMessage}>
            {this.state.errors}
          </Typography>

          <Box sx={webStyle.createRangeButtonContainer}>
            <Button
              style={webStyle.rangeButton}
              variant="contained"
              onClick={this.handleCreateMapping}
            >
              {configJSON.createMapping}
            </Button>

            <Button
              style={webStyle.rangeButton}
              variant="contained"
              onClick={this.handleCreateMappingModalClose}
            >
              {configJSON.btnCancel}
            </Button>
          </Box>
        </Box>
      </Modal>
    );
  };

  renderUserSelection = () => {
    return (
      <Box>
        <Button data-test-id="btnBasicUser" onClick={this.handleUserSelection}>
          {configJSON.basicUserText}
        </Button>
        <Button data-test-id="btnAdminUser" onClick={this.handleAdminSelection}>
          {configJSON.adminUserText}
        </Button>
      </Box>
    );
  };
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Container maxWidth={"md"} style={webStyle.container}>
            {this.state.loading && (
              <Box style={webStyle.loadingContainer}>
                <Box style={webStyle.circularContainer}>
                  <CircularProgress />
                </Box>
              </Box>
            )}
            {!this.state.userRole && this.renderUserSelection()}
            {Boolean(this.state.userRole) && this.renderTabContainer()}
            {Boolean(this.state.userRole) &&
              (this.state.tabIndex === 0 || this.state.tabIndex === 1) &&
              this.renderList()}
            {Boolean(this.state.userRole) && this.renderDialog()}
            {Boolean(this.state.userRole) &&
              this.state.tabIndex === 2 &&
              this.state.userRole === "admin" &&
              this.renderRanges()}
            {Boolean(this.state.userRole) && this.renderCreateRangeModal()}
            {Boolean(this.state.userRole) &&
              this.state.tabIndex === 3 &&
              this.state.userRole === "admin" &&
              this.renderMappings()}
            {Boolean(this.state.userRole) &&
              this.renderCreateUpdateMappingsModal()}
          </Container>
        </ThemeProvider>
      </StyledEngineProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  loadingContainer: {
    position: "absolute" as "absolute",
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgb(0, 0, 0, .4)",
    zIndex: 1,
  },
  tabCenter: {
    justifyContent: "center",
  },
  filterContain: {
    cursor: "pointer",
    position: "absolute" as "absolute",
    right: "21px",
  },
  circularContainer: {
    position: "absolute" as "absolute",
    left: "50%",
    top: "40%",
    transform: "translate(-50%, -50%)",
  },
  container: {
    boxShadow: "1px 0 10px 1px rgb(0 0 0 / 10%",
    marginTop: 10,
    marginBottom: 10,
  },
  listConatiner: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
    width: "100%",
  },
  header: {
    position: "relative" as "relative",
    display: "flex",
    fontSize: 20,
  },
  tabScoreheader: {
    position: "absolute" as "absolute",
    left: "500px",
  },
  ListContainer: {
    backgroundColor: "#e6e6e6",
    marginTop: 5,
    width: "100%",
  },
  activeUserContainer: {
    boxShadow: "1px 0 10px 1px rgb(0 0 0 / 10%",
    backgroundColor: "#9b9595",
    marginTop: 5,
    width: "100%",
  },
  w100: {
    width: "100%",
  },
  paginator: {
    justifyContent: "center",
    padding: "10px",
  },
  starContainer: {
    color: "yellow",
    backgroundColor: "transparent",
    textShadowColor: "black",
    textShadowOffset: { width: 1, height: 1 },
    textShadowRadius: 2,
    fontSize: 30,
  },
  scoreConatiner: {
    display: "flex",
    justifyContent: "flex-end",
  },
  notFound: {
    height: "500px",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  },
  diaogContainer: {
    width: "500px",
  },
  dialogScore: {
    padding: "10px",
  },
  dialogCheck: {
    padding: "5px",
  },
  rangeList: {
    width: "100%",
    maxWidth: 360,
    bgcolor: "background.paper",
  },
  rangeButtonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    margin: 10,
  },
  mappingButtonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    margin: 10,
  },
  rangeButton: {
    margin: 10,
  },
  createRangeButtonContainer: {
    margin: 20,
  },
  createRangeForm: {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  },
  rangeTextField: {
    marginLeft: 5,
    marginRight: 5,
  },
  errorMessage: {
    margin: 10,
    color: "red",
  },
  rangeTextFieldContainer: {
    display: "flex",
    flexDirection: "row",
    margin: 10,
  },
  selectCourseContainer: {
    width: 120,
    margin: 10,
  },
};
// Customizable Area End
