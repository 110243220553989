import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import moment from "moment";
export const configJSON = require("./config");
// Customizable Area End
export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    txtInputValue: string;
    txtSavedValue: string;
    enableField: boolean;
    // Customizable Area Start
    isStartDateClicked: boolean;
    startDate: string;
    isEndDateClicked: boolean,
    endDate: string
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class WeatherIntegrationController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            txtInputValue: "",
            txtSavedValue: "A",
            enableField: false,
            // Customizable Area Start
            isStartDateClicked: false,
            startDate: "",
            isEndDateClicked: false,
            endDate: ""
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End

    }

    // Customizable Area Start
    dateTextHandler(date: string) {
        if (date?.length > 0) {
            return this.state.startDate
        }
        return configJSON.startDate
    }

    endDateTextHandler(date: string) {
        if (date?.length > 0) {
            return this.state.endDate
        }
        return configJSON.endDate
    }

    btnDisableHandler(date: string) {
        return date?.length == 0 ? true : false
    }

    opacityHandler(date: string) {
        return date?.length == 0 ? 0.3 : 1
    }

    calendarHandler() {
        this.setState({
            isEndDateClicked: true
        })
    }

    startDateCalendarHandler() {
        this.setState({
            isStartDateClicked: true
        })
    }

    modalBackPressHandler() {
        this.setState({
            isEndDateClicked: false
        })
    }

    startDateModalBackBtn() {
        this.setState({
            isStartDateClicked: false
        })
    }

    navigationHandler() {
        this.props.navigation.navigate("LocationFinder", {
            startDate: this.state.startDate, endDate: this.state.endDate
        })
    }

    startDateSelector(date: string) {
        this.setState({
            startDate: date
        })
    }

    endDateSelector(date: string) {
        this.setState({
            endDate: date
        })
    }

    applyStartDateBtnHandler() {
        this.setState({
            isStartDateClicked: false,
        })
    }

    applyEndDateBtnHandler() {
        this.setState({
            isEndDateClicked: false,
        })
    }

    modalBackDropHandler() {
        this.state.isStartDateClicked ? this.startDateModalBackBtn() : this.modalBackPressHandler()
    }

    minimumDateHandler() {
        return !this.state.isEndDateClicked ? moment(Date.now()).format("YYYY-MM-DD") : this.state.startDate
    }

    dateDataUpdate(date: string) {
        this.state.isStartDateClicked ? this.startDateSelector(date) : this.endDateSelector(date)
    }

    btnDisableEnableHandler() {
        return this.state.startDate?.length > 0 && this.state.endDate?.length > 0 ? false : true
    }

    btnOpacityHandler() {
        return this.state.startDate?.length > 0 && this.state.endDate?.length > 0 ? 1 : 0.3
    }

    applyConditionalHandler() {
        this.state.isStartDateClicked ? this.applyStartDateBtnHandler() : this.applyEndDateBtnHandler()
    }
    // Customizable Area End
}
