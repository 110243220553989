import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { useRunEngine } from "../../utilities/src/hooks/useRunEngine";
import { useBlockHelpers } from "../../utilities/src/hooks/useBlockHelpers";
import EventDetailView from "./EventDetailView";
// Customizable Area Start
import { SelectChangeEvent } from "@mui/material";
import React, { ChangeEvent, FocusEvent, useEffect, useReducer, useRef, useState } from "react";
import { Platform } from "react-native";
import moment from "moment";
import { EventAction, EventState, IEventKind, INotify, IRepeat, ModalAction, ModalState } from "./types";
import { REPEAT_PICKER_ITEMS, NOTIFY_PICKER_ITEMS, EVENT_KIND_PICKER_ITEMS } from "./constants";
// Customizable Area End

export const configJSON = require("./config");

export interface ViewProps {
  // Customizable Area Start
  testID: string;
  event?: EventState;
  modal: ModalState;
  eventId?: string;
  isLoading: boolean;
  repeatPickerItems: { label: IRepeat; value: IRepeat }[];
  notifyPickerItems: { label: INotify; value: INotify }[];
  eventKindPickerItems: { label: IEventKind; value: IEventKind }[];
  eventKindFieldProps: {
    onPress: () => void;
  };
  titleFieldProps: {
    onChangeText: (text: string) => void;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  };
  dateFieldProps: {
    onPress: () => void;
  };
  timeFieldProps: {
    onPress: () => void;
  };
  repeatFieldProps: {
    onPress: () => void;
  };
  notifyFieldProps: {
    onPress: () => void;
  };
  notesFieldProps: {
    onChangeText: (text: string) => void;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  };
  eventKindModalProps: {
    visible: boolean;
    items: { label: IEventKind; value: IEventKind }[];
    selectedValue: string;
    onRequestClose: () => void;
    onBackdropPress: () => void;
    onValueChange: (itemValue: string) => void;
    onChange: (event: ChangeEvent<HTMLSelectElement>) => void;
    onConfirm: () => void;
  };
  repeatModalProps: {
    visible: boolean;
    items: { label: IRepeat; value: IRepeat }[];
    selectedValue: string;
    onRequestClose: () => void;
    onBackdropPress: () => void;
    onValueChange: (itemValue: string) => void;
    onChange: (event: ChangeEvent<HTMLSelectElement>) => void;
    onConfirm: () => void;
  };
  notifyModalProps: {
    visible: boolean;
    items: { label: INotify; value: INotify }[];
    selectedValue: string;
    onRequestClose: () => void;
    onBackdropPress: () => void;
    onValueChange: (itemValue: string) => void;
    onChange: (event: ChangeEvent<HTMLSelectElement>) => void;
    onConfirm: () => void;
  };
  datePickerModalProps: {
    open: boolean;
    date: Date;
    mode: "date";
    onConfirm: (date: Date) => void;
    onCancel: () => void;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  };
  timePickerModalProps: {
    open: boolean;
    date: Date;
    mode: "time";
    onConfirm: (time: Date) => void;
    onCancel: () => void;
    onBlur: (event: FocusEvent<HTMLInputElement>) => void;
  };
  actionButtonProps: {
    onPress: () => void;
  };
  deleteButtonProps: {
    onPress: () => void;
  };
  // Customizable Area End
}

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

const subscribedMessages = [
  // Customizable Area Start

  MessageEnum.RestAPIResponceMessage,
  MessageEnum.SessionResponseMessage,
  MessageEnum.NavigationPayLoadMessage,
  // Customizable Area End

];

// Customizable Area Start
const eventReducer = (state: EventState, action: EventAction) => {
  switch (action.type) {
    case "kind":
      return { ...state, kind: action.payload };
    case "title":
      return { ...state, title: action.payload };
    case "date":
      return { ...state, date: action.payload };
    case "time":
      return { ...state, time: action.payload };
    case "repeat":
      return { ...state, repeat: action.payload };
    case "notify":
      return { ...state, notify: action.payload };
    case "notes":
      return { ...state, notes: action.payload };
    default:
      return state;
  }
};

const modalReducer = (state: ModalState, action: ModalAction) => {
  switch (action.type) {
    case "date":
      return { ...state, date: !state.date };
    case "time":
      return { ...state, time: !state.time };
    case "repeat":
      return { ...state, repeat: !state.repeat };
    case "notify":
      return { ...state, notify: !state.notify };
    case "eventKind":
      return { ...state, eventKind: !state.eventKind };
    default:
      return state;
  }
};
// Customizable Area End


const EventDetail: React.FC<Props> = ({ navigation, id }) => {
  // Customizable Area Start

  const getEventCallId = useRef<string>("");
  const createEventCallId = useRef<string>("");
  const updateEventCallId = useRef<string>("");
  const deleteEventCallId = useRef<string>("");
  // Customizable Area End

  // Customizable Area Start
  const initialEventState: EventState = {
    kind: "",
    title: "",
    date: new Date(),
    time: new Date(),
    repeat: configJSON.everyDay,
    notify: configJSON._15MinutesBefore,
    notes: "",
  };

  const initialModalState: ModalState = {
    date: false,
    time: false,
    repeat: false,
    notify: false,
    eventKind: false,
  };

  const [event, setEvent] = useReducer(eventReducer, initialEventState);
  const [eventId, setEventId] = useState<string | undefined>(undefined);
  const [modal, toggleModal] = useReducer(modalReducer, initialModalState);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [token, setToken] = useState<string>("");

  const eventIdRef = useRef<string | undefined>(undefined);
  eventIdRef.current = eventId;
  const tokenRef = useRef<string>("");
  tokenRef.current = token;
  // Customizable Area End

  // Customizable Area Start
  const {
    sendBlockMessage,
    sendNetworkRequest,
    setReceiveCallback,
    subscribe,
    unsubscribeFromMessage,
  } = useRunEngine();

  const { extractNetworkResponse } = useBlockHelpers();

  const getToken = () => {
    const message: Message = new Message(getName(MessageEnum.SessionRequestMessage));
    sendBlockMessage(message);
  };

  const getEvent = (tokenArg?: string, eventId?: string) => {
    if (!eventIdRef.current || !eventId) {
      return;
    }

    setIsLoading(true);
    const headers = {
      "Content-Type": configJSON.apiContentType,
      token: tokenArg || tokenRef.current,
    };

    sendNetworkRequest(
      getEventCallId,
      configJSON.getEventsMethod,
      `${configJSON.eventsEndpoint}/${eventIdRef.current || eventId}`,
      headers
    );
  };

  const createEvent = () => {
    if (!event.title) {
      return;
    }

    setIsLoading(true);
    const headers = {
      "Content-Type": configJSON.apiContentType,
      token: tokenRef.current,
    };

    const attributes = {
      title: event.title,
      event_type: event.kind,
      time: moment(event.time).format("HH:mm"),
      date: moment(event.date).format("DD MMM YYYY"),
      repeat: event.repeat,
      notify: event.notify,
      notes: event.notes,
    };

    const data = { attributes };
    const httpBody = { data };

    sendNetworkRequest(
      createEventCallId,
      configJSON.createEventMethod,
      configJSON.eventsEndpoint,
      headers,
      httpBody
    );
  };

  const updateEvent = () => {
    if (!event.title) {
      return;
    }

    setIsLoading(true);
    const headers = {
      "Content-Type": configJSON.apiContentType,
      token: tokenRef.current,
    };

    const attributes = {
      title: event.title,
      event_type: event.kind,
      time: moment(event.time).format("HH:mm"),
      date: moment(event.date).format("DD MMM YYYY"),
      repeat: event.repeat,
      notify: event.notify,
      notes: event.notes,
    };

    const data = { attributes };
    const httpBody = { data };

    sendNetworkRequest(
      updateEventCallId,
      configJSON.updateEventMethod,
      `${configJSON.eventsEndpoint}/${eventId}`,
      headers,
      httpBody
    );
  };

  const deleteEvent = () => {
    setIsLoading(true);
    const headers = {
      "Content-Type": configJSON.apiContentType,
      token: tokenRef.current,
    };

    sendNetworkRequest(
      deleteEventCallId,
      configJSON.deleteEventMethod,
      `${configJSON.eventsEndpoint}/${eventId}`,
      headers
    );
  };
  // Customizable Area End


  const receive = (from: string, message: Message) => {
    // Customizable Area Start
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const sessionData = message.getData(getName(MessageEnum.SessionResponseData));
      if (sessionData.eventKind) {
        setEvent({
          type: "kind",
          payload: sessionData.eventKind,
        });
      }
      if (sessionData.eventId) {
        setEventId(sessionData.eventId);
        getEvent(tokenRef.current, sessionData.eventId);
      }
    } else if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let resToken = message.getData(getName(MessageEnum.SessionResponseToken));
      setToken(resToken);
      if (resToken) {
        getEvent(resToken);
      }
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const { apiRequestCallId, responseJson } = extractNetworkResponse(message);
      if (responseJson.data) {
        if (apiRequestCallId === createEventCallId.current) {
          setIsLoading(false);
          createEventCallId.current = "";
          navigation.navigate("AllEvents");
        }
        if (apiRequestCallId === updateEventCallId.current) {
          setIsLoading(false);
          updateEventCallId.current = "";
          navigation.navigate("AllEvents");
        }
        if (apiRequestCallId === getEventCallId.current) {
          setEvent({
            type: "kind",
            payload: responseJson.data.attributes.event_type,
          });
          setEvent({
            type: "title",
            payload: responseJson.data.attributes.title,
          });
          setEvent({
            type: "date",
            payload: new Date(responseJson.data.attributes.date),
          });
          setEvent({
            type: "time",
            payload: new Date(responseJson.data.attributes.time),
          });
          setEvent({
            type: "repeat",
            payload: responseJson.data.attributes.repeat,
          });
          setEvent({
            type: "notify",
            payload: responseJson.data.attributes.notify,
          });
          setEvent({
            type: "notes",
            payload: responseJson.data.attributes.notes,
          });
          setIsLoading(false);
          getEventCallId.current = "";
        }
      } else if (responseJson.errors) {
        setIsLoading(false);
      } else {
        if (apiRequestCallId === deleteEventCallId.current) {
          setIsLoading(false);
          deleteEventCallId.current = "";
          navigation.navigate("AllEvents");
        }
      }
    }
  };

  // Customizable Area Start
  useEffect(() => {
    setReceiveCallback(receive);

    subscribedMessages.forEach((message) => subscribe(message));

    if (!token) {
      getToken();
    }

    return () => {
      subscribedMessages.forEach((message) => unsubscribeFromMessage(message));
    };
  }, [token]);

  const handleMainAction = () => {
    if (eventId !== "0" && eventId !== undefined) {
      updateEvent();
      return;
    }
    createEvent();
  };

  const eventKindFieldProps = {
    onPress: () => toggleModal({ type: "eventKind" }),
  };

  const titleFieldProps = {
    onChangeText: (text: string) => setEvent({ type: "title", payload: text }),
    onChange: (event: ChangeEvent<HTMLInputElement>) => setEvent({ type: "title", payload: event.currentTarget.value }),
  };

  const dateFieldProps = {
    onPress: () => toggleModal({ type: "date" }),
  };

  const timeFieldProps = {
    onPress: () => toggleModal({ type: "time" }),
  };

  const repeatFieldProps = {
    onPress: () => toggleModal({ type: "repeat" }),
  };

  const notifyFieldProps = {
    onPress: () => toggleModal({ type: "notify" }),
  };

  const notesFieldProps = {
    onChangeText: (text: string) => setEvent({ type: "notes", payload: text }),
    onChange: (event: ChangeEvent<HTMLInputElement>) => setEvent({ type: "notes", payload: event.currentTarget.value }),
  };

  const eventKindModalProps = {
    visible: modal.eventKind,
    items: EVENT_KIND_PICKER_ITEMS,
    selectedValue: event.kind,
    onRequestClose: () => toggleModal({ type: "eventKind" }),
    onBackdropPress: () => toggleModal({ type: "eventKind" }),
    onValueChange: (itemValue: string) => setEvent({ type: "kind", payload: itemValue }),
    onChange: (event: ChangeEvent<HTMLSelectElement>) => setEvent({ type: "kind", payload: event.target.value }),
    onConfirm: () => toggleModal({ type: "eventKind" }),
  };

  const repeatModalProps = {
    visible: modal.repeat,
    items: REPEAT_PICKER_ITEMS,
    selectedValue: event.repeat,
    onRequestClose: () => toggleModal({ type: "repeat" }),
    onBackdropPress: () => toggleModal({ type: "repeat" }),
    onValueChange: (itemValue: string) => setEvent({ type: "repeat", payload: itemValue }),
    onChange: (event: ChangeEvent<HTMLSelectElement>) => setEvent({ type: "repeat", payload: event.target.value }),
    onConfirm: () => toggleModal({ type: "repeat" }),
  };

  const notifyModalProps = {
    visible: modal.notify,
    items: NOTIFY_PICKER_ITEMS,
    selectedValue: event.notify,
    onRequestClose: () => toggleModal({ type: "notify" }),
    onBackdropPress: () => toggleModal({ type: "notify" }),
    onValueChange: (itemValue: string) => setEvent({ type: "notify", payload: itemValue }),
    onChange: (event: ChangeEvent<HTMLSelectElement>) => setEvent({ type: "notify", payload: event?.target?.value || "" }),
    onConfirm: () => toggleModal({ type: "notify" }),
  };

  const datePickerModalProps = {
    open: modal.date,
    date: event.date,
    mode: "date",
    onConfirm: (date: Date) => {
      toggleModal({ type: "date" });
      setEvent({ type: "date", payload: date });
    },
    onCancel: () => toggleModal({ type: "date" }),
    onChange: (event: ChangeEvent<HTMLInputElement>) => setEvent({ type: "date", payload: new Date(event.currentTarget.value) }),
  };

  const timePickerModalProps = {
    open: modal.time,
    date: event.time,
    mode: "time",
    onConfirm: (time: Date) => {
      toggleModal({ type: "time" });
      setEvent({ type: "time", payload: time });
    },
    onCancel: () => toggleModal({ type: "time" }),
    onBlur: (event: FocusEvent<HTMLInputElement>) => {
      const date = new Date();
      const time = event.currentTarget.value.split(":");
      date.setHours(parseInt(time[0], 10), parseInt(time[1], 10));
      toggleModal({ type: "time" });
      setEvent({ type: "time", payload: date });
    },
  };

  const actionButtonProps = {
    onPress: handleMainAction,
  };

  const deleteButtonProps = {
    onPress: deleteEvent,
  };

  const viewProps: ViewProps = {
    testID: "EventDetailView",
    event,
    modal,
    eventId: eventIdRef.current,
    isLoading,
    repeatPickerItems: REPEAT_PICKER_ITEMS,
    notifyPickerItems: NOTIFY_PICKER_ITEMS,
    eventKindPickerItems: EVENT_KIND_PICKER_ITEMS,
    eventKindFieldProps,
    titleFieldProps,
    dateFieldProps,
    timeFieldProps,
    repeatFieldProps,
    notifyFieldProps,
    notesFieldProps,
    eventKindModalProps,
    repeatModalProps,
    notifyModalProps,
    //@ts-expect-error #R18RN640524 This TS error is a result of running React 18 with RN 0.64 and will be resolved when we upgrade to RN 0.70
    datePickerModalProps,
    //@ts-expect-error #R18RN640524 This TS error is a result of running React 18 with RN 0.64 and will be resolved when we upgrade to RN 0.70
    timePickerModalProps,
    actionButtonProps,
    deleteButtonProps,
  };
  // Customizable Area End
  return <EventDetailView {...viewProps} />;
};

export default EventDetail;
