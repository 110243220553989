import AlertBlockController from './AlertBlockController';
import { Button, StyleSheet, Text, View } from 'react-native';
import React from 'react';
import { Popover } from '@builder/component-library';
import { Props } from './AlertBlockController';

export default class AlertBock extends AlertBlockController {
  constructor(props: Props) {
    super(props);
  }

  getNeutralButton() {
    const neutralButton = this.state.buttons.find(button => button.type === "neutral");
    if (!neutralButton) {
      return null;
    }
    return (
      <Button title={neutralButton.label} onPress={() => this.onButtonPress(neutralButton)} />
    )
  }

  render() {
    if (!this.state.title && !this.state.body) {
      return null;
    }

    return (
      <Popover isVisible={this.state.isVisible} onPressCloseIcon={ () => this.setState({isVisible: false})}>
        <View style={styles.modalParentContainer}>
          {this.state.title && (
            <Text style={styles.title}>{this.state.title}</Text>
          )}

          <Text style={styles.body}>{this.state.body}</Text>
          <View style={styles.allButtonContainer}>
            {this.getNeutralButton()}
            <View style={styles.twoButtonContainer}>
              {this.state.buttons
                .filter((button) => button.type !== "neutral")
                .map((button, index) => (
                  <Button
                    key={index}
                    title={button.label}
                    onPress={() => this.onButtonPress(button)}
                  />
                ))}
            </View>
          </View>
        </View>
      </Popover>
    );
  }
}

const styles = StyleSheet.create({
  allButtonContainer: { flexDirection: "row", justifyContent: "space-between" },
  body: { padding: 0, fontSize: 24, marginBottom: 15, marginTop: 7 },
  twoButtonContainer: { flexDirection: "row", marginLeft: 40 },
  positiveButton: { marginLeft: 10 },
  modalParentContainer: {
    padding: 10,
    display: "flex",
  },
  title: {
    padding: 0,
    fontSize: 30,
    fontWeight: 'bold',
    includeFontPadding: false,
  },
});
