Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.apiContentType = "application/json";
exports.eventsEndpoint = "events/events";
exports.getEventsMethod = "GET";
exports.createEventMethod = "POST";
exports.updateEventMethod = "PUT";
exports.deleteEventMethod = "DELETE";

exports.eventType = "Event Type";
exports.title = "Title";
exports.create = "Create";
exports.createEvent = "Create Event";
exports.update = "Update";
exports.delete = "Delete";
exports.location = "Location";
exports.date = "Date";
exports.time = "Time";
exports.confirm = "Confirm";
exports.repeat = "Repeat";
exports.notify = "Notify";
exports.notes = "Notes";
exports.loading = "Loading...";
exports.noData = "No Data";

exports.birthday = "Birthday";
exports.schoolDropOff = "School Drop Off";
exports.schoolPickUp = "School Pick Up";
exports.getTogether = "Get Together";
exports.meeting = "Meeting";
exports.outForCoffee = "Out for Coffee";
exports.gym = "Gym";
exports.flight = "Flight";
exports.doctor = "Doctor";
exports.vet = "Vet";
exports.rent = "Rent";
exports.fees = "Fees";
exports.activity = "Activity";
exports.custom = "Custom";

exports.oneDayBefore = "One Day Before";
exports.oneHourBefore = "One Hour Before";
exports._30MinutesBefore = "30 Minutes Before";
exports._15MinutesBefore = "15 Minutes Before";

exports.never = "Never";
exports.everyDay = "Every Day";
exports.everyWeek = "Every Week";
exports.everyMonth = "Every Month";
exports.every2Months = "Every 2 Months";
exports.everyYear = "Every Year";
exports.custom = "Custom";

exports.day = "Day";
exports.week = "Week";
exports.month = "Month";
exports.year = "Year";
// Customizable Area End
