import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { rain, snow, summer } from "./assets";
import { WeatherDataType } from "./type";
import Scale from "../../../components/src/Scale";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    txtInputValue: string;
    txtSavedValue: string;
    enableField: boolean;
    // Customizable Area Start
    weatherData: WeatherDataType[] | null
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

let locationWeatherData = [
    {
        "image": snow,
        "id": 1,
        "temp": "12"
    },
    {
        "image": rain,
        "id": 2,
        "temp": "20"
    },
    {
        "image": summer,
        "id": 3,
        "temp": "34"
    }
]

export default class WeatherDetailsController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            txtInputValue: "",
            txtSavedValue: "A",
            enableField: false,
            // Customizable Area Start
            weatherData: locationWeatherData
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End

    }

    // Customizable Area Start
    goBackHandler() {
        this.props.navigation.goBack()
    }

    marginHandler(index: number) {
        return index == 0 ? Scale(10) : Scale(5)
    }
    // Customizable Area End
}
