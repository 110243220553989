import * as React from "react";
// Customizable Area Start
import {
  Container,
  Typography,
  Card,
  CardContent,
  Box,
} from "@mui/material";

// Customizable Area End

import { configJSON, ViewProps } from "./CreateEvent";

const CreateEventView: React.FC<ViewProps> = ({
  // Customizable Area Start
  testID,
  events,
  eventCardProps,
  // Customizable Area End
}) => {
  return (
    // Customizable Area Start
    <Container style={webStyle.container} data-testid={testID} maxWidth="sm">
      {events.map((event, index) => {
        return (
          <Box
            style={webStyle.link}
            key={index}
            onClick={() => eventCardProps.onClick(event.title)}
            data-testid={"EventCard"}
          >
            <Card>
              <CardContent style={webStyle.cardContent}>
                <img
                  style={webStyle.image}
                  src={event.image}
                  alt={event.title}
                />
                <Typography>{event.title}</Typography>
              </CardContent>
            </Card>
          </Box>
        );
      })}
    </Container>
    // Customizable Area End
  );
};

const webStyle = {
  // Customizable Area Start
  container: {
    display: "flex",
    flexDirection: "column" as const,
    gap: "16px",
    marginTop: "16px",
  },
  link: {
    textDecoration: "none",
  },
  cardContent: {
    display: "flex",
    alingItems: "center",
    gap: "16px",
  },
  image: {
    width: "24px",
    height: "24px",
  },
  // Customizable Area End
};

export default CreateEventView;
