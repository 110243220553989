import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { Keyboard } from "react-native";
import { cross, search } from "./assets";
import { LocationData } from "./type";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    txtInputValue: string;
    txtSavedValue: string;
    enableField: boolean;
    // Customizable Area Start
    location: string;
    suggestedLocationData: LocationData[];
    isSuggestionModalOpen: boolean;
    // Customizable Area End
}

let dummyData = [
    {
        "id": 1,
        "name": "Bangalore"
    },
    {
        "id": 2,
        "name": "Borivali"
    },
    {
        "id": 3,
        "name": "Bhubaneswar"
    }
]
interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}
export default class LocationFinderController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            txtInputValue: "",
            txtSavedValue: "A",
            enableField: false,
            // Customizable Area Start
            location: "",
            suggestedLocationData: dummyData,
            isSuggestionModalOpen: false,
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    suggestionHandler(data: LocationData) {
        Keyboard.dismiss()
        this.setState({
            isSuggestionModalOpen: false,
            location: data.name,
        })
    }

    goBack() {
        this.props.navigation.goBack()
    }

    focusHandler() {
        this.setState({
            isSuggestionModalOpen: true
        })
    }

    locationTextHandler(text: string) {
        this.setState({
            location: text
        })
    }

    borderHandler(index: number) {
        return index + 1 == this.state.suggestedLocationData?.length ? 0 : 1
    }

    navigationHandler() {
        let startDate = this.props.navigation.getParam("startDate")
        let endDate = this.props.navigation.getParam("endDate")
        this.props.navigation.navigate("WeatherDetails", { startDate: startDate, endDate: endDate, location: this.state.location })
    }

    opacityHandler() {
        return this.state.location?.length > 0 ? 1 : 0.3
    }

    btnDisableHandler() {
        return this.state.location?.length > 0 ? false : true
    }

    clearLocationData() {
        this.setState({
            location: ""
        })
    }

    imageHandler() {
        return this.state.location?.length > 0 ? cross : search
    }
    // Customizable Area End
}
