// Customizable Area Start
import { configJSON } from "./AllEvents";

export const EVENT_KIND_PICKER_ITEMS = [
  {
    label: configJSON.birthday,
    value: configJSON.birthday,
  },
  {
    label: configJSON.schoolDropOff,
    value: configJSON.schoolDropOff,
  },
  {
    label: configJSON.schoolPickUp,
    value: configJSON.schoolPickUp,
  },
  {
    label: configJSON.getTogether,
    value: configJSON.getTogether,
  },
  {
    label: configJSON.meeting,
    value: configJSON.meeting,
  },
  {
    label: configJSON.outForCoffee,
    value: configJSON.outForCoffee,
  },
  {
    label: configJSON.gym,
    value: configJSON.gym,
  },
  {
    label: configJSON.flight,
    value: configJSON.flight,
  },
  {
    label: configJSON.doctor,
    value: configJSON.doctor,
  },
  {
    label: configJSON.vet,
    value: configJSON.vet,
  },
  {
    label: configJSON.rent,
    value: configJSON.rent,
  },
  {
    label: configJSON.fees,
    value: configJSON.fees,
  },
  {
    label: configJSON.activity,
    value: configJSON.activity,
  },
  {
    label: configJSON.custom,
    value: configJSON.custom,
  },
];

export const REPEAT_PICKER_ITEMS = [
  {
    label: configJSON.never,
    value: configJSON.never,
  },
  {
    label: configJSON.everyDay,
    value: configJSON.everyDay,
  },
  {
    label: configJSON.everyWeek,
    value: configJSON.everyWeek,
  },
  {
    label: configJSON.everyMonth,
    value: configJSON.everyMonth,
  },
  {
    label: configJSON.everyYear,
    value: configJSON.everyYear,
  },
  {
    label: configJSON.custom,
    value: configJSON.custom,
  },
];

export const NOTIFY_PICKER_ITEMS = [
  {
    label: configJSON.oneDayBefore,
    value: configJSON.oneDayBefore,
  },
  {
    label: configJSON.oneHourBefore,
    value: configJSON.oneHourBefore,
  },
  {
    label: configJSON._30MinutesBefore,
    value: configJSON._30MinutesBefore,
  },
  {
    label: configJSON._15MinutesBefore,
    value: configJSON._15MinutesBefore,
  },
];
// Customizable Area End
