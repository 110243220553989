import CreateEventView from "./CreateEventView";
// Customizable Area Start
import * as React from "react";
import { IEventKind } from "./types";
import {
  cakeIcon,
  schooldropIcon as schoolDropIcon,
  schoolpickIcon as schoolPickIcon,
  VetIcon as vetIcon,
  RentIcon as rentIcon,
  GetTogetherIcon as getTogetherIcon,
  MeetingIcon as meetingIcon,
  DoctorIcon as doctorIcon,
  GymIcon as gymIcon,
  FlightIcon as flightIcon,
  OutforCoffeeIcon as outForCoffeeIcon,
  CustomIcon as customIcon,
  ActivityIcon as activityIcon,
  FeesIcon as feesIcon,
} from "./assets";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { useRunEngine } from "../../utilities/src/hooks/useRunEngine";
// Customizable Area End

export const configJSON = require("./config");

export interface ViewProps {
  // Customizable Area Start
  testID: string;
  events: {
    image: any;
    title: IEventKind;
  }[];
  eventCardProps: {
    onPress: (eventKind: IEventKind) => void;
    onClick: (eventKind: IEventKind) => void;
  };
  // Customizable Area End
}

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

const CreateEvent: React.FC<Props> = ({ navigation, id }) => {
  // Customizable Area Start
  const EVENTS: {
    image: any;
    title: IEventKind;
  }[] = [
    {
      image: cakeIcon,
      title: "Birthday",
    },
    {
      image: schoolDropIcon,
      title: "School Drop Off",
    },
    {
      image: schoolPickIcon,
      title: "School Pick Up",
    },
    {
      image: getTogetherIcon,
      title: "Get Together",
    },
    {
      image: meetingIcon,
      title: "Meeting",
    },
    {
      image: outForCoffeeIcon,
      title: "Out for Coffee",
    },
    {
      image: gymIcon,
      title: "Gym",
    },
    {
      image: flightIcon,
      title: "Flight",
    },
    {
      image: doctorIcon,
      title: "Doctor",
    },
    {
      image: vetIcon,
      title: "Vet",
    },
    {
      image: rentIcon,
      title: "Rent",
    },
    {
      image: feesIcon,
      title: "Fees",
    },
    {
      image: activityIcon,
      title: "Activity",
    },
    {
      image: customIcon,
      title: "Custom",
    },
  ];
  // Customizable Area End

  // Customizable Area Start
  const { sendBlockMessage } = useRunEngine();
  // Customizable Area End

  // Customizable Area Start
  const navigateToEventDetail = (eventKind?: IEventKind) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "EventDetail"
    );

    message.addData(getName(MessageEnum.NavigationPropsMessage), {
      navigation,
      id,
    });

    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {
      eventKind,
    });
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

    sendBlockMessage(message);
  };

  // Customizable Area End

  // Customizable Area Start
  const handleSelectEvent = (eventKind?: IEventKind) => {
    navigateToEventDetail(eventKind);
  };

  const eventCardProps = {
    onPress: (eventKind: IEventKind) => handleSelectEvent(eventKind),
    onClick: (eventKind: IEventKind) => handleSelectEvent(eventKind),
  };

  const viewProps: ViewProps = {
    testID: "CreateEventView",
    events: EVENTS,
    eventCardProps,
  };
  // Customizable Area End
  return <CreateEventView {...viewProps} />;
};

export default CreateEvent;
