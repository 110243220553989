import * as React from "react";
// Customizable Area Start
import {
  Container,
  Box,
  Button,
  Typography,
  TextField,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import moment from "moment";
// Customizable Area End

import { configJSON, ViewProps } from "./EventDetail";

const EventDetailView: React.FC<ViewProps> = ({
  // Customizable Area Start
  testID,
  event,
  eventId,
  isLoading,
  titleFieldProps,
  notesFieldProps,
  eventKindModalProps,
  repeatModalProps,
  notifyModalProps,
  datePickerModalProps,
  timePickerModalProps,
  actionButtonProps,
  deleteButtonProps,
  // Customizable Area End
}) => {
  // Customizable Area Start
  if (isLoading) {
    return (
      <Box>
        <Typography>{configJSON.loading}</Typography>
      </Box>
    );
  }

  if (!event) {
    return (
      <Box>
        <Typography>{configJSON.noData}</Typography>
      </Box>
    );
  }

  return (
    <Container style={webStyle.container} data-testid={testID} maxWidth="sm">
      <form noValidate autoComplete="off" style={webStyle.form}>
        {eventId ? (
          <FormControl variant="outlined">
            <InputLabel htmlFor="event-kind">{configJSON.eventType}</InputLabel>
            <Select
              label={configJSON.eventType}
              value={event.kind}
              onChange={(event) =>
                //@ts-expect-error #R18RN640524 This TS error is a result of running React 18 with RN 0.64 and will be resolved when we upgrade to RN 0.70
                eventKindModalProps.onChange(event as SelectChangeEvent<string>)
              }
              inputProps={{
                name: "event-kind",
                id: "event-kind",
              }}
            >
              {eventKindModalProps.items.map((item) => {
                return (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        ) : null}
        <TextField
          label={configJSON.title}
          variant="outlined"
          value={event.title}
          onChange={titleFieldProps.onChange}
        />
        <TextField
          label={configJSON.date}
          variant="outlined"
          type="date"
          value={moment(event.date).format("YYYY-MM-DD")}
          onChange={datePickerModalProps.onChange}
        />
        <TextField
          label={configJSON.time}
          variant="outlined"
          type="time"
          defaultValue={moment(event.time).format("hh:mm")}
          onBlur={timePickerModalProps.onBlur}
        />
        <FormControl variant="outlined">
          <InputLabel htmlFor="repeat">{configJSON.repeat}</InputLabel>
          <Select
            label={configJSON.repeat}
            value={event.repeat}
            onChange={(event) =>
              //@ts-expect-error #R18RN640524 This TS error is a result of running React 18 with RN 0.64 and will be resolved when we upgrade to RN 0.70
              repeatModalProps.onChange(event as SelectChangeEvent<string>)
            }
            inputProps={{
              name: "repeat",
              id: "repeat",
            }}
          >
            {repeatModalProps.items.map((item) => {
              return (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <FormControl variant="outlined">
          <InputLabel htmlFor="notify">{configJSON.notify}</InputLabel>
          <Select
            label={configJSON.notify}
            value={event.notify}
            onChange={(event) =>
              //@ts-expect-error #R18RN640524 This TS error is a result of running React 18 with RN 0.64 and will be resolved when we upgrade to RN 0.70
              notifyModalProps.onChange(event as SelectChangeEvent<string>)
            }
            inputProps={{
              name: "notify",
              id: "notify",
            }}
          >
            {notifyModalProps.items.map((item) => {
              return (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <TextField
          label={configJSON.notes}
          multiline
          maxRows={4}
          variant="outlined"
          value={event.notes}
          onChange={notesFieldProps.onChange}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={actionButtonProps.onPress}
        >
          {eventId ? configJSON.update : configJSON.create}
        </Button>
        {eventId ? (
          <Button color="secondary" onClick={deleteButtonProps.onPress}>
            {configJSON.delete}
          </Button>
        ) : null}
      </form>
    </Container>
  );
  // Customizable Area End
};

const webStyle = {
  // Customizable Area Start
  container: {
    display: "flex",
    flexDirection: "column" as const,
    gap: "16px",
    marginTop: "16px",
  },
  form: {
    display: "flex",
    flexDirection: "column" as const,
    gap: "16px",
  },
  // Customizable Area End
};

export default EventDetailView;
