Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.apiEndPointGetLanguage = "bx_block_profile/languages";
exports.apiEndPointUpdateLanguge = "bx_block_profile/language_update";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.postAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LanguageOptions";
exports.labelBodyText = "LanguageOptions Body";
exports.btnExampleTitle = "CLICK ME";
// Customizable Area End
