import React from "react";
// Customizable Area Start
import {
    Text,
    Image,
    TouchableOpacity,
    View,
    ScrollView,
    SafeAreaView,
    Dimensions, 
    StyleSheet
    // Customizable Area Start
    // Customizable Area End
} from "react-native";
import { calendar } from "./assets";
import WeatherIntegrationController, {
    Props,
    configJSON
} from "./WeatherIntegrationController";
import Scale from "../../../components/src/Scale";
import { Calendar } from "react-native-calendars";
import Modal from "react-native-modal";
// Customizable Area End
export default class WeatherIntegration extends WeatherIntegrationController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    customCalendar(visible: boolean, date: string, testId: string) {
        return <Modal
            testID="calendarModal"
            backdropOpacity={0.5}
            isVisible={visible}
            animationIn={"slideInUp"}
            animationOut={"slideOutDown"}
            onBackdropPress={() => this.modalBackDropHandler()}
        >
            <SafeAreaView>
                <ScrollView style={{ padding: Scale(8) }}>
                    <View style={[styles.card, styles.elevation]}>
                        <View style={{ justifyContent: "space-between" }}>
                            <Calendar
                                testID="calendar"
                                minDate={this.minimumDateHandler()}
                                onDayPress={day => this.dateDataUpdate(day.dateString)}
                                markedDates={{
                                    [date]: {
                                        selected: true,
                                        disableTouchEvent: true,
                                        selectedColor: "#CDB8D7",
                                        selectedTextColor: '#000000',
                                    },
                                }}
                            />
                        </View>
                        <TouchableOpacity
                            testID={testId}
                            onPress={() => this.applyConditionalHandler()}
                            activeOpacity={0.7}
                            disabled={this.btnDisableHandler(date)}
                            style={[styles.applyBtn, {
                                opacity: this.opacityHandler(date)
                            }]}>
                            <Text
                                style={styles.btnText}
                            >{
                                    configJSON.apply
                                }
                            </Text>
                        </TouchableOpacity>
                    </View>
                </ScrollView>
            </SafeAreaView>
        </Modal>
    }
    // Customizable Area End

    render() {
        const { startDate, endDate, isEndDateClicked, isStartDateClicked } = this.state
        return (
            // Customizable Area Start
            <SafeAreaView style={styles.container}>
                <View style={{ flex: 0.01 }} />
                <View
                    style={styles.subContainer}
                >
                    <Text
                        style={styles.headerText}
                    >
                        {configJSON.heading}
                    </Text>
                </View>
                <View style={styles.subView}>
                    <View>
                        <Text style={styles.textStyle2}>{configJSON.helpText1}</Text>
                        <Text style={styles.textStyle1}>{configJSON.helpText2} </Text>
                        <View style={styles.dateContainer}>
                            <>
                                <Text
                                    style={styles.explanationText}
                                >
                                    {this.dateTextHandler(startDate)}
                                </Text>
                            </>
                            <TouchableOpacity
                                testID="startDateCalendarBtn"
                                activeOpacity={0.7}
                                style={styles.imageContainer}
                                onPress={() => this.startDateCalendarHandler()}>
                                <Image
                                    source={calendar}
                                    style={styles.goBackImage}
                                    resizeMode="contain"
                                />
                            </TouchableOpacity>
                        </View>
                        {
                            this.customCalendar(isStartDateClicked, this.state.startDate, "startDateSelectionBtn")
                        }
                        <View style={styles.dateContainer}>
                            <>
                                <Text
                                    style={styles.explanationText}>
                                    {this.endDateTextHandler(endDate)}
                                </Text>
                            </>
                            <TouchableOpacity
                                testID="endDateCalendarBtn"
                                onPress={() => this.calendarHandler()}
                                activeOpacity={0.7}
                                style={styles.imageContainer}
                            >
                                <Image
                                    source={calendar}
                                    style={styles.goBackImage}
                                    resizeMode="contain"
                                />
                            </TouchableOpacity>
                            {
                                this.customCalendar(isEndDateClicked, this.state.endDate, "endDateSelectionBtn")
                            }
                        </View>
                    </View>
                    <TouchableOpacity
                        activeOpacity={0.7}
                        disabled={this.btnDisableEnableHandler()}
                        testID="navigationToNextPageBtn"
                        style={[styles.acceptBtn, { opacity: this.btnOpacityHandler() }]}
                        onPress={() => this.navigationHandler()}>
                        <Text
                            style={styles.btnText}>
                            {configJSON.btnText}
                        </Text>
                    </TouchableOpacity>
                </View>
            </SafeAreaView >
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: "white",
    },
    subContainer: {
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        marginLeft: Scale(10)
    },
    subView: {
        flex: 0.94,
        padding: Scale(10),
        justifyContent: "space-between"
    },
    dateContainer: {
        flexDirection: "row",
        alignItems: "center",
        marginTop: Scale(30),
        backgroundColor: "#E5E5E5",
        width: Dimensions.get("screen").width - Scale(20),
        height: Scale(50),
        borderRadius: Scale(15),
        paddingHorizontal: Scale(10)
    },
    imageContainer: {
        flex: 1,
        alignItems: "flex-end",
        justifyContent: "center"
    },
    goBackImage: {
        width: Scale(20),
        height: Scale(20),
        tintColor: "#000000"
    },
    headerText: {
        flex: 1,
        textAlign: "center",
        fontSize: Scale(20),
        fontFamily: "PlayfairDisplay-Bold",
        letterSpacing: Scale(0.25),
    },

    explanationText: {
        fontSize: Scale(14),
        fontFamily: "Nunito-SemiBold",
        letterSpacing: Scale(0.15)
    },
    btnText: {
        fontSize: Scale(13),
        fontFamily: "Nunito-Bold",
        fontWeight: "700",
        textTransform: "uppercase",
        letterSpacing: Scale(0.15)
    },
    acceptBtn: {
        backgroundColor: "#CDB8D7",
        width: Dimensions.get("screen").width - Scale(20),
        height: Scale(50),
        justifyContent: "center",
        alignItems: "center",
        borderRadius: Scale(15),
        marginBottom: Scale(10)
    },
    applyBtn: {
        backgroundColor: "#CDB8D7",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: Scale(10),
        marginTop: Scale(15),
        width: Dimensions.get("screen").width - Scale(120),
        height: Scale(40),
        borderRadius: Scale(20),
    },
    card: {
        flex: 0.47,
        backgroundColor: "white",
        zIndex: 5,
        borderRadius: Scale(15),
        padding: Scale(25),
    },
    elevation: {
        shadowOffset: { width: 0, height: 4 },
        shadowOpacity: 1,
        shadowRadius: 3,
        shadowColor: "gray",
        elevation: 4
    },
    textStyle1: {
        marginTop: Scale(10),
        fontSize: Scale(18),
        fontFamily: "PlayfairDisplay-SemiBold",
        letterSpacing: Scale(0.2)
    },
    textStyle2: {
        marginTop: Scale(15),
        fontSize: Scale(14),
        fontFamily: "PlayfairDisplay-Regular",
        letterSpacing: Scale(0.15)
    },
    suggestionText: {
        fontSize: Scale(16),
        fontFamily: "Nunito-SemiBold",
        letterSpacing: Scale(0.15),
        fontWeight: "600"
    },
    locationContainer: {
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: Scale(30),
        backgroundColor: "#E5E5E5",
        width: Dimensions.get("screen").width - Scale(20),
        height: Scale(50),
        borderRadius: Scale(15),
        padding: Scale(15)
    },
    weatherDataContainer: {
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: Scale(10),
        backgroundColor: "#E5E5E5",
        width: Dimensions.get("screen").width - Scale(20),
        borderRadius: Scale(15)
    },
    weatherImageContainer: {
        flexDirection: "row",
        alignItems: "center"
    },
    weatherImage: {
        height: Scale(50),
        width: Scale(80)
    },
    dateContainerWithBackGround: {
        marginTop: Scale(8),
        backgroundColor: "#CDB8D7",
        borderRadius: Scale(5),
        padding: Scale(5)
    },
    subHeading: {
        marginTop: Scale(30),
        padding: Scale(5)
    },
    subHeadingText: {
        fontFamily: "Nunito-Bold",
        fontSize: Scale(18),
        letterSpacing: Scale(0.15)
    },
    dateText: {
        textAlign: "center",
        fontSize: Scale(15),
        fontFamily: "Nunito-SemiBold",
    },
    weatherContainer: {
        flex: 0.94,
        padding: Scale(10)
    },
    tempText: {
        fontSize: Scale(14),
        fontFamily: "Nunito-SemiBold",
    },
    locationModal: {
        backgroundColor: "#E5E5E5",
        marginTop: Scale(5),
        borderRadius: Scale(10)
    },
    locationSubContainer: {
        borderBottomColor: "#D2D3D5",
        paddingVertical: Scale(12)
    }
});
// Customizable Area End