import React from "react";
import { View, Button, StyleSheet } from "react-native";
import { useNavigate } from "react-router-dom";

const TopNav = () => {

  const navigate = useNavigate()
  return (
    <View style={styles.main}>
      <Button title="Home" onPress={() => navigate("/")} />
    </View>
  );
};

const styles = StyleSheet.create({
  main: {
    flexDirection: "row",
    justifyContent: "space-evenly",
    backgroundColor: "#cccccc"
  }
});

export default TopNav;
