Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Gamification";
exports.labelBodyText = "Gamification Body";

exports.btnExampleTitle = "CLICK ME";
exports.btnLoadMore = "Load MORE";
exports.btnSelect = "Selected";
exports.btnReset = "Reset";
exports.btnCancel = "Cancel";
exports.btnApply = "Apply";

exports.labelRankingText = "Ranking";
exports.labelRankText = "Rank";
exports.labelLeaderboardText = "LeaderBoard";
exports.labelCourseIDText = "Course Id";
exports.labelUserIDText = "User Id";
exports.labelScoreText = "Score";
exports.labelTopScoreText = "Top Score";
exports.labelMin = "Minimum";
exports.labelMax = "Maxmium";
exports.labelCourseText = "Course";
exports.labelUserText = "User";
exports.labelSearchCourseText = "Search Course...";
exports.labelSearchUserText = "Search User...";
exports.labelFilterText = "Filter";
exports.labelNoRecordText = "No Record Found";
exports.ranges = "Ranges";
exports.createRange = "Create New Range";
exports.enterRange = "Enter range data";
exports.error = "Error";
exports.range = "Range";
exports.value = "Value";
exports.success = "Success";
exports.mappings = "Mappings";
exports.createMapping = "Create New Mapping";
exports.courseId = "Course Id";
exports.rangeId = "Range Id";
exports.createMappingError = "Please enter mapping information";
exports.updateMapping = "Update Mapping";
exports.selectCourse = "Select Course";
exports.basicUserText = "Basic User";
exports.adminUserText = "Admin";
exports.createUpdate = "Create/Update Mapping";

exports.createRangeApi = "educational_gamification/ranges";
exports.deleteRangeApi = "educational_gamification/ranges/";
exports.getRangesApi = "educational_gamification/ranges";
exports.createMappingApi =
  "educational_gamification/create_score_credit_mapping/";
exports.updateMappingApi = "educational_gamification/edit_score_credit_mapping";
exports.getLeaderboardApi = "educational_gamification/leaderboard/";
exports.getPerformanceTrackerCoursesApi = "tracker/performance_tracker_courses";
exports.getUserRankingApi = "educational_gamification/logged_user_get_ranking/";

exports.itemsPerPage = 10;
exports.maxRank = 5;
exports.maxScore = 100;
// Customizable Area End
